body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.portfolio-item img {
  width: 100%;
  height: 350px; 
 object-fit: cover;
}

.custom-form {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.custom-form h4 {
  font-size: 24px;
  color: #004080;
  margin-bottom: 10px;
  text-align: center;
}

.custom-form p {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 20px;
  text-align: center;
}

.custom-form-group {
  margin-bottom: 15px;
}

.custom-form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
  width: 100%;
}

.custom-form-control:focus {
  border-color: #004080;
  box-shadow: none;
}

.custom-button {
  background-color: #1bb1dc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #fff;
  border: 1px solid #1bb1dc;
  color: #1bb1dc;
}

#sendmessage {
  display: none;
  color: #28a745;
  text-align: center;
  margin-top: 15px;
}

#errormessage {
  display: none;
  color: #dc3545;
  text-align: center;
  margin-top: 15px;
}

.custom-validation {
  color: #dc3545;
  font-size: 12px;
  margin-top: 5px;
}

.custom-text-center {
  text-align: center;
}
.custom-logo{
  width: 300px ;
  height: 300px;
  display: flex;
}

.custom-portfolio {
  padding: 60px 15px;
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center; 
  background-color: #f9f9f9;
  min-height: 100vh; 
}

.custom-portfolio-item {
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
 
}

.custom-portfolio-item:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.custom-portfolio-image {
  flex: 1;
  max-width: 300px;
}

.custom-portfolio-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  display: block;
}

.custom-portfolio-details {
  flex: 2;
  margin-left: 30px;
}
.custom-portfolio-details h4 {
  color: #1bb1dc;
  font-size: 24px;
  font-weight: bold;
}
.custom-btn {
  display: inline-block;
  background-color: #1cb1dc;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.custom-btn:hover {
  background-color: transparent;
  color: #1cb1dc;
  border: 1px solid #1cb1dc;
}


@media (max-width: 768px) {
  .custom-portfolio-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .custom-portfolio-details {
    margin-left: 0;
    margin-top: 15px;
  }
}
